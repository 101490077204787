<template>
  <div>CompanyNews</div>
</template>

<script>
export default {
  name: 'CompanyNews'
}
</script>

<style scoped>

</style>
